<template>
  <v-row>
    <v-col cols="12" sm="3">
      <v-text-field
        outlined
        label="שם מבצע"
        v-model="item.campaign_name"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="3">
      <v-menu
        v-model="menu_1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="item.start_date"
            prepend-icon=""
            prepend-inner-icon="mdi-calendar"
            readonly
            :rules="[(v) => !!v || 'חובה']"
            v-bind="attrs"
            v-on="on"
            outlined
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              תאריך התחלה
            </template></v-text-field
          >
        </template>
        <v-date-picker
          v-model="item.start_date"
          @input="menu_1 = false"
        ></v-date-picker>
      </v-menu>
    </v-col>

    <v-col cols="12" sm="3">
      <v-menu
        v-model="menu_2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="item.end_date"
            prepend-icon=""
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
            :rules="[
              () => !!compare_dates || `נא לסמן תאריך אחרי תאריך התחלה`,
              (v) => !!v || 'חובה',
            ]"
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              עד תאריך
            </template></v-text-field
          >
        </template>
        <v-date-picker
          v-model="item.end_date"
          @input="menu_2 = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
<!-- 
    <v-col cols="12" sm="3">
      <v-text-field
        outlined
        label="السلة"
        type="number"
        v-model.number="item.min_cart"
      ></v-text-field>
    </v-col> -->

    <!-- <v-col cols="12" sm="3">
      <v-text-field
        outlined
        type="number"
        v-model.number="item.maximum_sale_number"
        :rules="[(v) => !!v || 'حقل مطلوب' ]"
      >
       <template slot="label">
          <strong class="red--text">*</strong>
          الحد الأقصى للبيع
        </template>  
      </v-text-field>
    </v-col>
    <v-col cols="12" sm="3">
      <v-text-field
        outlined
        label="عدد المباع"
        type="number"
        readonly
        v-model.number="item.sale_counter"
      >
        <template slot="append">
          <v-btn @click="$emit('reset_counter')" color="primary" class="mt-0 zero">تصفير</v-btn>
        </template>
      </v-text-field>
    </v-col> 


    <v-col cols="12" sm="3">
      <v-text-field
        outlined
        type="number"
        v-model.number="item.maximum_user_sale_number"
        :rules="[(v) => !!v || 'حقل مطلوب' ]"
      >
      <template slot="label">
          <strong class="red--text">*</strong>
          الحد الأقصي للبيع للفرد
        </template>
      </v-text-field>
    </v-col> -->



    <v-col cols="12" sm="3">
      <v-select
        outlined
        label="סטטוס"
        v-model="item.status"
        :items="status"
        item-value="value"
        item-text="text"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["compare_dates", "item"],
  name: "Basic",
  data() {
    return {
      menu_1: false,
      menu_2: false,
      status: [
        {
          text: "לא פעיל",
          value: 0,
        },
        {
          text: "פעיל",
          value: 1,
        },
      ],
    };
  },
};
</script>
