<template>
  <div>
    <v-card elevation="0">
      <v-card-title>מוצרים</v-card-title>
  
      <v-row v-for="(pro, i) in item.products" :key="i">
        <v-col cols="2">
          <v-select
            outlined
            v-model="pro.type"
            :items="pro_types"
            item-text="text"
            item-value="value"
            :rules="[(v) => !!v || 'חובה']"
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              סוג הנחה
            </template>
          </v-select>
        </v-col>
        <!-- / col -->
        <v-col cols="3" class="d-flex">

          <div>
            <img
              v-if="pro.product.img"
              :src="`${$store.state.def_path}/${pro.product.img}`"
              width="50"
              height="50"
              alt=""
            />
            <img
              width="50"
              height="50"
              v-if="!pro.product.img"
              :src="`${$store.state.def_path}/uploads/none_img.png`"
              alt=""
            />
          </div>

          <v-text-field
            @click="open_dialog(i, pro.type)"
            readonly
            outlined
            :rules="[(v) => !!v || 'חובה']"
            v-model="pro.product.name"
            label="ברקוד מוצר"
          >
          
            <template slot="prepend-inner">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="pro.product_id"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>error_outline</v-icon></v-btn
                  >
                </template>
                <span>{{
                  `${pro.product.barcode} ${ pro.product.name ? "-" : ""} ${pro.product.name}`
                }}</span>
              </v-tooltip>
            </template>
            <template slot="append">
              <v-btn @click="open_dialog(i,pro.type)" icon>
                <v-icon>add</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <!-- / col -->
        <v-col cols="2" v-if="pro.type == 'product'">
          <v-text-field outlined type="number" v-model.number="pro.x_quantity">
            <template slot="label"> كمية المنتج الاخر </template>
          </v-text-field>
        </v-col>
        <!-- / col -->
        <!-- <v-col cols="2" v-if="pro.type == 'percentage'">
          <v-text-field
            :rules="[
              (v) => !!v || 'حقل مطلوب',
              (v) => v <= 100 || 'اكبر من 100',
            ]"
            outlined
            type="number"
            v-model.number="pro.percentage"
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              النسبة
            </template>
            <template slot="append"> % </template>
          </v-text-field>
        </v-col> -->
        <!-- / col -->
        <v-col cols="2" v-if="pro.type == 'price' || pro.type == 'package'">
          <v-text-field
            outlined
            type="number"
            v-model.number="pro.sale_price"
            :rules="[(v) => !!v || 'חובה']"
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              סכום הנחה
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="2" v-if="pro.type == 'package'">
          <v-text-field
            outlined
            type="number"
            v-model.number="pro.count"
            :rules="[(v) => !!v || 'חובה']"
          >
            <template slot="label">
              <strong class="red--text">*</strong>
              כמות
            </template>
          </v-text-field>
        </v-col>

        <!-- / col -->

        <!-- / col -->
        <v-col cols="1">
          <v-btn
            @click="delete_product(pro.id, i)"
            min-width="30"
            x-large
            height="55"
            color="error"
          >
            <v-icon> delete </v-icon>
          </v-btn>
        </v-col>
        <!-- / col -->
      </v-row>
      <!--/ row -->
      <v-card-actions class="pa-0 mb-10">
        <v-btn @click="new_product()" color="primary" outlined>מוצר חדש</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog max-width="500" v-model="dialog">
      <v-card>
        <v-card-title>   חיפוש לפי ברקוד </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3">
          <v-alert v-if="!pro_not_found" class="text-center" dense type="error"
            > אין מוצר מתאים</v-alert
          >

          <v-text-field
            v-model="barcode"
            outlined
            class="pt-4"
            label="ברקוד"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="search_loader"
            :disabled="search_loader"
            color="primary"
            @click="search_product()"
            >חיפוש</v-btn
          >
          <v-btn @click="close_dialog()" color="error" text>ביטול</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Products",
  props: ["item"],
  data() {
    return {
      pro_index: -1,
      pro_type: null,
      barcode: null,
      dialog: false,
      search_loader: false,
      pro_not_found: true,
      pro_types: [
        {
          value: "price",
          text: "מחיר מבצע",
        },
        {
          value: "package",
          text: "כמות במחיר",
        },
        // {
        //   value: "percentage",
        //   text: "نسبة مئوية",
        // },,
      ],
    };
  },
  methods: {
    search_product() {
      if (this.barcode && !this.search_loader) {
        this.search_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `search_barcode/${this.barcode}`,
              method: "get",
            },
          })
          .then((res) => {
            this.search_loader = false;
            this.pro_not_found = res.data.data.status;

            if (this.pro_not_found) {
              if (this.pro_type != "product") {
                this.item.products[this.pro_index].product_id =res.data.data.id;
                this.item.products[this.pro_index].product.name = res.data.data.name
                this.item.products[this.pro_index].product.barcode = res.data.data.barcode;
                this.item.products[this.pro_index].product.img = res.data.data.img;
              }

              this.$nextTick(() => {
                this.close_dialog();
              });
            } else {
              this.item.products[this.pro_index].product_id = null;
              this.item.products[this.pro_index].product = {
                name:"",
                barcode:"",
                img:null,
              }
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },

    open_dialog(index, type) {
      this.dialog = true;
      this.pro_index = index;
      this.pro_type = type;
      console.log(this.pro_type);
    },
    close_dialog() {
      this.dialog = false;
      this.pro_not_found = true;
      this.pro_index = -1;
      this.pro_type = null;
      this.barcode = null;
    },

    new_product() {
      this.item.products.push({
        id: null,
        type: "price",
        product: [
          {
            name: "",
            barcode:"",
            img:null
          },
        ],
        product_id: null,
        percentage: null,
        sale_price: null,
        count: 0,
      });
    },
    delete_product(id, index) {
      if (id) {
        this.$store
          .dispatch("public__request", {
            config: {
              url: `campaign_product/${id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.item.products.splice(index, 1);
          });
      } else {
        this.item.products.splice(index, 1);
      }
    },
  },
};
</script>
