<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <v-card>
          <v-card-text>
            <h1 class="text-h4 my-5" v-if="edit_loaded">{{ page_title }}</h1>

            <v-row v-if="!edit_loaded">
              <v-col v-for="i in 5" :key="i" cols="6" sm="3">
                <v-skeleton-loader
                  type="list-item-two-line"
                ></v-skeleton-loader>
              </v-col>
            </v-row>

            <div v-if="!edit_loaded">
              <v-col v-for="i in 7" :key="i" cols="12" sm="6">
                <v-skeleton-loader
                  type="list-item-two-line"
                ></v-skeleton-loader>
              </v-col>
            </div>

            <v-form ref="form" v-if="edit_loaded">
              <Basic
                @reset_counter="reset_counter()"
                :compare_dates="compare_dates"
                :item="item"
              ></Basic>
              <Products :item="item"></Products>
              <v-btn
                v-if="
                  this.$route.params.id
                    ? this.$store.getters.permission('campaigns edit')
                    : this.$store.getters.permission('campaigns create')
                "
                @click="save()"
                class="mb-10"
                color="primary"
                width="200"
                height="50"
                x-large
                :disabled="save_loader"
                :loading="save_loader"
                >שמירה</v-btn
              >
              <!--/ btn -->
            </v-form>
          </v-card-text>
        </v-card>

        <v-snackbar left v-model="snackbar_success">
          <p class="white--text">עודכן  {{ page_title }}</p>
          <template v-slot:action>
            <v-icon color="success" class="ms-3">check_circle</v-icon>
          </template>
        </v-snackbar>

        <v-snackbar color="error" left v-model="snackbar_err">
          <p class="white--text"> בבקשה לבדוק הכל</p>
          <template v-slot:action>
            <v-icon color="white" class="ms-3">error</v-icon>
          </template>
        </v-snackbar>
      </div>
    </div>
  </v-container>
</template>
<script>
import Basic from "@/components/campaigns/basic";
import Products from "@/components/campaigns/products";

export default {
  name: "Campaign",
  data() {
    return {
      save_loader: false,
      products: [],
      snackbar_err: false,
      snackbar_success: false,
      edit_loaded: true,
      item: {
        id: null,
        campaign_name: "",
        start_date: "",
        end_date: "",
        min_cart: 0,
        maximum_sale_number: 0,
        maximum_user_sale_number: 0,
        status: 1,
        products: [
          {
            id: null,
            type: "price",
            product: [
              {
                name: null,
              },
            ],
            product_x: [
              {
                name: null,
              },
            ],
            product_id: null,
            product_x_id: null,
            percentage: null,
            sale_price: null,
            x_quantity: 1,
            count: 0,
            exist: false,
          },
        ],
      },
    };
  },
  components: {
    Basic,
    Products,
  },
  computed: {
    page_title() {
      return this.item.id ? "עריכת מבצע" : "מבצע חדש";
    },
    compare_dates() {
      let start_date = new Date(this.item.start_date).getTime();
      let end_date = new Date(this.item.end_date).getTime();
      return start_date <= end_date;
    },
  },
  methods: {
    reset_counter() {
      this.item.sale_counter = 0;
    },
    save() {
      if (
        this.$refs.form.validate() &&
        !this.save_loader &&
        (this.$route.params.id
          ? this.$store.getters.permission("campaigns edit")
          : this.$store.getters.permission("campaigns create"))
      ) {
        // return console.log(this.item);
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: this.$route.params.id
                ? `campaigns/${this.$route.params.id}`
                : "campaigns",
              method: this.$route.params.id ? "put" : "post",
            },
            data: this.item,
          })
          .then((res) => {
            this.snackbar_success = true;
            setTimeout(() => {
              this.$router.push("/campaigns");
            }, 1000);
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        this.snackbar_err = true;
      }
    },
    edit() {
      if(this.$store.getters.permission("campaigns edit")) {
        this.$store
        .dispatch("public__request", {
          config: {
            url: `campaigns/${this.$route.params.id}`,
            method: "get",
          },
        })
        .then((res) => {
          console.log(res);
          this.item = Object.assign({}, res.data.data);
          this.edit_loaded = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
      }
    },
  },
  mounted() {
    // this.get_sections();
    if (this.$route.params.id) {
      this.edit_loaded = false;
      this.edit();
    }
  },
};
</script>
